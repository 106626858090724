/*Scroll Bar Slim SYTART  DLCheckboxView*/
.scrollbar-slim::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    overflow-x: hidden;
    border-radius: 5px
}

@media (min-width: 767px) {
    .scrollbar-slim::-webkit-scrollbar {
        width: 10px;
        background-color: #F5F5F5;
        overflow-x: hidden;
    }
}

.scrollbar-slim::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 5px;
    overflow-x: hidden;

}

@media (max-width: 767px) {
    .scrollbar-slim::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
        overflow-x: hidden;
    }
}

/*Scroll Bar Slim END DLCheckboxView*/
