
.autocomplete-input-container {
    min-height: 36px;
    padding: 0px 0px 0px 0px !important;
}
.autocomplete-input-container > div > p {
    font-size: 14px;
    color: #575757;
    font-weight: 500;
}

.MuiInputBase-root{
    border-radius: 7px !important;
    border-width: 2px;
}

.icon-cross {
    color: #333333;
}