.search-input {
    max-width: 350px;
}

.select-filter{
    max-width: 250px;
}

.border-end-black {
    border-right: 1px solid var(--gray-dark) !important;
}


/*Filter button dot CSS starts*/
.icon-container {
    position: relative;
    display: inline-block;
}

.dot {
    position: absolute;
    top: -5px;
    right: -13px;
    padding: 1px;
    background-color: white;
}
/*Filter button dot CSS ends*/

.mobile-border-bottom{
   border-bottom:  5px solid var(--gray)
}

/*Overlay CSS Begins*/
.open-modal-btn {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 20px;
}

.modal-overlay {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: baseline;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 20px 20px 0 0;
    height: 85%
}

.create-options {
    display: flex;
    flex-direction: column;
}

.option {
    padding: 10px;
    background-color: #f5f5f5;
    margin: 5px 0;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}
/*Overlay CSS Ends*/


.badge-green {
    background-color: var(--success);
    color: var(--white);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    min-width: 45px;
    height: 24px;
    font-weight: 500;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}

.badge-blue {
    background-color: var(--blue-dark);
    color: var(--white);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    min-width: 45px;
    height: 24px;
    font-weight: 500;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}

.badge-red {
    background-color: var(--danger);
    color: var(--white);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    min-width: 45px;
    height: 24px;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}

.badge-amber {
    background-color: var(--secondary);
    color: var(--white);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    min-width: 45px;
    height: 24px;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}

.badge-grayDark {
    background-color: var(--gray-dark);
    color: var(--white);
    border: none;
    border-radius: 20px;
    font-size: 12px;
    min-width: 45px;
    height: 24px;
    font-weight: 500;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}
