/*DLDocumentUploaderView*/
.dl-document-uploader > div {
    border: none !important;
    display: flex;
    justify-content: start;
    align-items: start;
    width: 100%;
    overflow: hidden !important;
    min-height: 100% !important;
}

.dl-document-uploader > div > label {
    border: none !important;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    padding-left: 16px;
    text-align: center;
    overflow: hidden !important;
    min-height: 100% !important;
}

/*DLDocumentUploaderView*/
.dl-document-uploader > div > .dzu-inputLabelWithFiles {
    /*display: inline-block !important;*/
    padding: 8px !important;
    margin-right: 8px !important;
    font-size: 20px !important;
    color: #fff !important;
    background-color: #003f61 !important;
    margin-top: 43px;
    width: 45%;
    display: none !important;
}
