.block-padding{
    padding : 20px 16px
}
.NSE-status-icon{
    margin-right: 9px;
    margin-left: 9px;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    font-size: 16px;
    display: block !important;
}
.NSE-status-progress-icon{
    margin-right: 9px;
    margin-left: 9px;
    height: 16px;
    width: 16px;
    border-radius: 16px;
    font-size: 16px;
    display: flex !important;
    align-content: center;
    justify-content: center;
}
.lds-spinner {
    /* change color here */
    color: var(--blue-dark);
}
.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
    box-sizing: border-box;
}
.lds-spinner {
    color: var(--blue-dark);
    display: inline-block;
    position: relative;
    width: 20px; /* Reduced size */
    height: 20px; /* Reduced size */
}
.lds-spinner div {
    transform-origin: 10px 10px; /* Adjusted for the new size */
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0.8px; /* Adjusted for new size */
    left: 9.2px; /* Adjusted for new size */
    width: 1.6px; /* Adjusted width */
    height: 4.4px; /* Adjusted height */
    border-radius: 20%;
    background: currentColor;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
