.cursor {
    cursor: pointer;
}

a {
    text-decoration: none;
}

* ::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

* ::-webkit-scrollbar-track {
    background: var(--blue-light) !important;
}

* ::-webkit-scrollbar-thumb {
    background: var(--gray-dark) !important;
    border-radius: 10px !important;
}

* ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-dark) !important;
}

.webkit-inline {
    display: -webkit-inline-flex !important;
}

.border {
    border: 1px solid var(--gray) !important;
}

.border-black {
    border: 1px solid var(--black) !important;
}

.button-image-container {
    border: 1px solid var(--gray-dark) !important;
    border-radius: 4px !important;
}

.button-image {
    height: 20px;
    width: 20px;
}

.border-left {
    border-left: 1px solid var(--gray) !important;
}

.border-right {
    border-right: 1px solid var(--gray) !important;
}

.border-bottom {
    border-bottom: 1px solid var(--gray) !important;
}

.border-top {
    border-top: 1px solid var(--gray) !important;
}


.border-top-black {
    border-top: 1px solid var(--black) !important;
}

.border-bottom-black {
    border-bottom: 1px solid var(--black) !important;
}

.border-rounded {
    border-radius: 8px !important;
}

.page-header {
    padding: 8px 30px;
}

.page-title {
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-semibold);
}

.page-subtitle {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    color: var(--gray-dark);
}

.page-container {
    padding: 0 30px 0 30px !important;
    background-color: transparent;
}

.resappbar-search-box-container > .dropdown {
    border: 1px solid var(--disable);
    border-radius: 25px !important;
    background-color: white;
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-grow: 1;
}

.resappbar-search-box-container .dropdown-toggle::after {
    display: none;
}

.resappbar-search-box-container .dropdown-menu.show {
    max-width: 1150px;
    max-height: 550px;
    overflow-y: auto;
    background-color: white;
}

.resappbar-search-box-container > .show dropdown. > button {
    border-radius: 25px !important;
}

.resappbar-search-box-container .btn-primary {
    --bs-btn-color: var(--black);
    --bs-btn-bg: none !important;
    --bs-btn-border-color: none !important;
    --bs-btn-hover-color: #fff;
    width: 100%;
}

.resappbar-search-box-container-options {
    border: 1px solid var(--gray-dark);
    border-radius: 7px !important;
    background-color: white;
    max-width: 1150px;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
}
.resappbar-search-box-container .btn.show {
    color: var(--black);
    background-color: transparent;
    border-color: transparent;
}
.resappbar-search-box-container .btn:hover {
    color: var(--black);
    background-color: transparent;
    border-color: var(--bs-btn-hover-border-color);
}

@media (max-width: 992px) {
    .page-header {
        padding: 16px;
    }

    .page-title {
        font-size: var(--font-size-lg);
    }

    .page-subtitle {
        font-size: var(--font-size-sm);
    }

    .page-container {
        padding: 0 15px 0 15px !important;
        background-color: transparent;
    }

}

.panel {
    border: 1px solid var(--gray);
    border-radius: 8px;
    opacity: 1;
    background-color: white;
    padding: 32px;
}

.section-title{
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
    padding-bottom: 12px;
    padding-top: 12px;
}

.panel-header {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    height: 50px !important;
    padding: 16px;
    background-color: var(--gray-light);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.panel-container {
    padding: 16px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.block-title {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
}


.link-primary {
    color: var(--link);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
}

.default-icon {
    color: var(--black);
    font-size: var(--font-size-md);
}

.disable-icon {
    color: var(--disable);
}

.success-icon {
    color: var(--success);
}

.warning-icon {
    color: var(--secondary);
}

.error-icon {
    color: var(--danger);
}

.page-header {
    font-weight: var(--font-weight-semibold);
    font-size: var(--font-size-xl);
}


.page-header-mobile {
    padding: 16px 16px 12px;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-semibold);
}

.data-label {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
    color: var(--gray-dark);
}

.data-value {
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-normal);
}

/* onboarding flow css*/

.menu-icon {
    color: var(--black);
}

.pagination-container .MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

.pagination-container .MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.sticky-bottom-buttons {
    position: fixed;
    background: var(--gray-light);
    bottom: 0;
    left: 0;
    padding: 8px 30px 8px 0;
    gap: 10px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
}

@media (max-width: 992px) {
    .sticky-bottom-buttons {
        padding: 8px 0 !important;
        justify-content: center !important;
    }
}

.bg-light {
    background-color: var(--gray-light) !important;
}


/*this is use in badge of listing screen*/
.badge-light {
    background-color: var(--gray);
    border: none;
    border-radius: 20px;
    font-size: 14px;
    min-width: 45px;
    font-weight: 400;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}


.status-dot-md {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
}

/*status dot css starts*/
.status-dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.primary {
    background-color: var(--blue-dark);
}

.warning {
    background-color: var(--secondary);
}

.info {
    background-color: var(--link);
}

.danger {
    background-color: var(--danger);
}

.yellow {
    background-color: var(--yellow);
}
.success {
    background-color: var(--success);
}

.grayDark {
    background-color: var(--gray-dark);
}

/*status dot css end*/


/* SM breakpoint */
@media (min-width: 576px) {
    /* CUSTOM WIDTHS */
    .w-sm-25 {
        width: 25% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }
}

/* MD breakpoint*/
@media (min-width: 768px) {
    /* CUSTOM WIDTHS */
    .w-sm-25 {
        width: 25% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }
}

/* LG breakpoint */
@media (min-width: 992px) {
    /* CUSTOM WIDTHS */
    .w-sm-25 {
        width: 25% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }
}

.error-container {
    display: flex;
    max-width: 100%;
}

.error-vertical-line {
    border-left: 5px solid var(--danger);
    height: 100%;
    min-height: 30px;
    border-radius: 7px 0 0 7px;
}

.error-panel {
    font-size: var(--font-size-sm);
    background-color: var(--danger-light);
    width: max-content;
    padding: 5px 8px;
}

.info-panel {
    font-size: var(--font-size-sm);
    background-color: var(--gray);
    width: max-content;
    border-radius: 4px;
    margin-left: 2px;
    padding: 2px 8px;
}

.warning-vertical-line {
    border-left: 5px solid var(--warning);
    min-height: 30px;
    border-radius: 7px 0 0 7px;
}

.warning-panel {
    font-size: var(--font-size-sm);
    background-color: var(--warning-light);
    width: max-content;
    padding: 5px 8px;
}

.success-vertical-line {
    border-left: 5px solid var(--blue-dark);
    min-height: 30px;
    border-radius: 7px 0 0 7px;
}

.success-panel {
    font-size: var(--font-size-sm);
    background-color: var(--info);
    width: max-content;
    padding: 5px 8px;
}


.css-7wsc1c-menu {
    font-size: var(--font-size-sm);
    font-family: var(--font-family-inter)
}

css-7ovhnc-MuiFormHelperText-root {
    margin-left: 8px;
    margin-right: 8px;
}

.tabWidthSingleField {
    width: 37.5%;
}

.vertical-line {
    border-left: 2px solid #666; /* Change the color and width as needed */
    z-index: -1;
    width: 2px;
    min-height: 20px;
}

.section-title-vertical-bar {
    border: 3px solid var(--blue-dark);
    minHeight: 20px;
    margin-top: 1px;
    margin-right: 4px;
    border-radius: 3px;
}

.vertical-step-icon {
    background-color: var(--gray);
    margin-right: 9px;
    margin-left: 9px;
    height: 16px;
    width: 16px;
    border-radius: 16px;
}

.step-connector {
    height: 100%;
    width: 1px;
    border: 1px solid;
    color: var(--gray);
}

.source-scheme-icon {
    background-color: var(--gray-dark);
    margin-right: 9px;
    margin-left: 9px;
    height: 8px;
    width: 8px;
    border-radius: 8px;
}

.target-scheme-icon {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid var(--gray-dark);
}

.black-border-bottom{
    border-bottom: 1px solid var(--gray-dark) !important;
}

.black-border-top{
    border-top: 1px solid var(--gray-dark);
}

.section-divider{
    border-bottom: 5px solid var(--gray);
    padding-bottom: 20px;
    padding-top: 20px;
}

.section-tabs-gap{
    margin-top: 12px;
    margin-bottom: 20px;
}

.otp-number-Input ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.kyc-display-status-icon{
    width: 16px;
    height: 16px;
}
.box-shadow{
    box-shadow: 8px 2px 5px 1px #ababab33
}
#doc-status-dropdown {
    -webkit-text-fill-color : var(--black)
}
.non-editable-inputs{
    padding: 8.5px 14px;
    min-height: 34px;
    height: 34px;
    font-size: 14px;
    font-weight: 500;
}
.non-editable-textArea{
    margin: 8.5px 14px;
    min-height: 64px;
    max-height: 64px;
    overflow-x: auto;
}
.non-editable-input-border{
    border: 1px solid var(--disable);
    border-radius: 7px;
}
.non-editable-inputs-label{
    font-size: 12px;
    color: var(--gray-dark);
    margin-bottom: 4px;
    line-height: 20px;
}

/* Multi-select dropdown selected input padding */
.multi-select-dropdown-input > div > div:nth-child(1){
    padding-bottom: 8px;
    padding-top: 8px;
}
.sip-popup{
    min-height: 417px;
}