.guardian-panel{
    margin-top: 115px;
    margin-left: 20px;
    padding: 20px;
}
.title-guardian{
    font-size:16px !important;
    margin-right: 4px;
    line-height: 24px;
}

.sub-title-guardian{
    color: #666666;
    font-size: 12px;
    margin-top: 24px;
}
.details-guardian{
    font-size: 12px;
}
.details-guardian-mobile{
    font-size: 14px;
}
.mobile-vertical-line{
    width: 86%;
}
.text-nominee{
    width: 98% !important;
}
.name-mobile-view
{
    color:#0073FF;
    font-size: 12px;
}
.subtext-mobile-view{
    color:#282828;
    font-size: 10px;
}
.minor-button{
    line-height:15px;
    padding-top: 4px;
    padding-bottom: 4px;
    color:#FFFFFF;
    width: 70px;
   background-color: var(--blue-dark)
}
.minor-button-details{
    line-height: 8px;
    padding: 12px 8px 12px 8px;
    font-size: 12px;
    color:var(--white);
    background-color: var(--blue-dark)
}
.remove-nominee{
    text-align: center;
}

.nominee-details-mobile{
    font-size: 20px;
    color:#282828;
}
.guardian-panel-mobile{
    background-color: var(--blue-light);
    border-radius: 8px;
}
.guardian-div{
    padding-top: 24px;
}

.input-field{
    line-height: 11px;
    padding-bottom: 16px;
}