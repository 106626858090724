@media (max-width: 992px) {
    .user-info {
        justify-content: space-between !important;
    }
}

.user-info {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: start;
}

.user-info .user-email {
    color: #0073e6;
    text-decoration: none;
}

.user-info .user-email:hover {
    text-decoration: underline;
}
