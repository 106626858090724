/* src/components/DocumentUploadStatus.css */
.document-upload-status {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    max-width: 300px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.document-upload-status h2 {
    font-size: 18px;
    margin-bottom: 10px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.document-upload-status ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.document-upload-status li {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
}

.document-upload-status .icon {
    margin-right: 10px;
}

.status-completed .icon {
    color: green;
}

.status-pending .icon {
    color: orange;
}
