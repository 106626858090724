:root { /* Colors: */

    --primary: #282828;
    --secondary: #FF9800;
    --tertiary: #FFF6E8;
    --success: #029C48;
    --success-light: #E1FFE5;
    --danger: #D72F2F;
    --danger-light: #FFEBEB;
    --blue-dark: #4F46E5;
    --blue-light: #FAFAFA;
    --gray-light: #F1F5F9;
    --gray-dark: #666666;
    --gray : #E2E8F0 ;
    --disable: #999999;
    --black : #282828;
    --white: #fff;
    --link: #0073ff;
    --warning: #F8B24F;
    --warning-light: #FFF6E8;
    --info: #E7EFFF;
    --yellow: #FFF500;

    --primary-text: #282828;
    --secondary-text: #FF9800;
    --tertiary-text: #FFFFFF;
    --success-text: #029C48;
    --white-text: #FFFFFF;
    --black-text: #282828;

    /* Font/text values */
    --font-family-inter: 'Poppins', sans-serif;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: normal;


    --font-size-xxs: 10px;
    --font-size-xs: 12px;
    --font-size-sm: 14px;
    --font-size-md: 16px;
    --font-size-lg: 20px;
    --font-size-xl: 24px;
    --font-size-xxl: 28px;
    --font-size-xxxl: 32px;
    --character-spacing-0: 0px;
    --line-spacing-18: 18px;
    --line-spacing-20: 20px;
}
