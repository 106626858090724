
.even-row-bg {
    background-color: var(--white);
}
.odd-row-bg {
    background-color: var(--white);
}
.table-border {
    background: white 0 0 no-repeat padding-box;
    border: 1px solid var(--gray-light);
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.table-td-mui {
    font-size: var(--font-size-xs) !important;
    padding: 0 16px;
}

@media (max-width: 575px) {
    .table-border {
        box-shadow: none;
        border: none;
        border-radius: 0;
    }
}

