#app-header-nav a {
    text-decoration: none;
    color: var(--black-text);
    font-size: var(--font-size-md);
    height: 24px;
}

#app-header-nav a:hover {
    border-bottom: 2px solid var(--black) !important;
}

#app-header-nav a.active {
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-semibold);
    border-bottom: 2px solid var(--black) !important;
}


.drop-down-menu {
    padding: 8px 0 !important;
    font-size: var(--font-size-md) !important;
}

.drop-down-menu:hover {
    background-color: var(--white) !important;
    text-decoration: underline !important;
}

.profile-img {
    width: 34px !important;
    height: 34px !important;
    object-fit: cover;
    font-size: var(--font-size-sm) !important;
}

.nav-text {
    color: var(--black-text) !important;
    font-size: 16px;
    height: 24px;
}

#app-header-nav a {
    text-decoration: none;
    color: var(--black-text);
    font-size: 16px;
    height: 24px;
}

#app-header-nav a:hover {
    border-bottom: 2px solid #282828 !important;
}

#app-header-nav a.active {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #282828 !important;
}

/*#app-header-nav a.active{*/
/*    !*text-decoration: underline !important;*!*/
/*    color: #0007F3 !important;*/
/*    font-weight: 500;*/
/*    border-bottom: 2px solid #0007F3 !important;*/
/*}*/
#app-header-nav-mobile a {
    text-decoration: none;
    color: var(--black-text);
    font-size: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex: 1;
}

#app-header-nav-mobile a.active {
    font-weight: 500;
    height: 100%;
}

#app-header-nav-mobile a > .horizontal-line {
    height: 2px;
    width: 83%;
    background-color: var(--white) !important;
    border-radius: 20px;
}

#app-header-nav-mobile a.active > .horizontal-line {
    height: 3px;
    width: 100%;
    background-color: var(--black) !important;
    border-radius: 20px;
}
#app-header-nav-mobile div{
    font-size: 12px;
}